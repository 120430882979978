import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import useAuth from '../../hooks/auth.hook'
import * as authSelectors from '../../redux/selectors/auth.selectors'

import Categories from './components/Categories'
import Collections from './components/Collections'

import styles from './Nav.module.css' 
import { FRONT_URL } from '../../const'


function Nav() {
    const navigate = useNavigate()
    const { logout } = useAuth()

    const isAuth = useSelector(authSelectors.isAuth)
    const isAdmin = useSelector(authSelectors.isAdmin)

    const accountHandler = () => {
        if(!isAuth) { return navigate('/login') }
        if(!isAdmin) { return navigate('/account/main') }
    }

    return (
        <div className={styles.nav}>
            <Link to="/" className={styles.logo}>
                <img src={`${FRONT_URL}/images/logo.svg`} alt="logo" />
            </Link>

            <div className={styles.menu}>
                <Categories />
                <Collections />
                <Link to="/about">about</Link>
            </div>

            <div className={styles.info}>
                <Link to="/info/offer-agreement">Info</Link>
                <Link to="/contacts">Contacts</Link>
                {!isAdmin && <Link to="/basket">Bag</Link>}
                {isAdmin && <Link to="/login" onClick={logout}>LogOut</Link>}
            </div>

            <div className={styles.account} onClick={accountHandler}>
                <svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.99891 5.20889C3.99891 7.47405 5.97621 9.41778 8.55219 9.41778C11.1282 9.41778 13.1055 7.47405 13.1055 5.20889C13.1055 2.94373 11.1282 1 8.55219 1C5.97621 1 3.99891 2.94373 3.99891 5.20889Z" stroke="black" strokeWidth="2"/>
                    <path d="M8.77492 10.0352V14.7705M8.77492 25.9201L14.2852 30.9999M8.77492 25.9201L5.50322 30.9999M8.77492 25.9201V15.8898M8.77492 14.7705L0.99939 11.4988M8.77492 14.7705V15.8898M8.77492 15.8898L14.2852 11.4988" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}

export default Nav