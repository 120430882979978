import React from 'react'
import styles from '../Info.module.css'

function DeliveryAndPayment() {
    return (
        <>
            <div className={styles.row}>Доставка товарів здійснюється компанією «Нова Пошта» на будь-яке зручне для вас місце отримання, а саме: на відділення/ за адресою/ у поштомат.</div>
            <div className={styles.row}>Відправлення <span className={styles.mark}>з наявності</span> здійснюються протягом 3-х робочих днів після підтвердження замовлення.</div>
            <div className={styles.row}>Замовлення, прийняті на <span className={styles.mark}>індивідуальне пошиття</span> передаються до служби доставки на 7 або 8 робочий день.</div>
            <div className={styles.row}>
                <p>Відрахунок терміну починається з наступного робочого дня після прийняття замовлення.</p>
                <p>Вихідні дні (субота та неділя) - в термін не входять.</p>
            </div>
            <div className={styles.row}>
                <p>Вартість доставки оплачує отримувач, згідно з тарифами компанії перевізника.</p>
                <p>Розрахувати середню вартість доставки компанією “Нова Пошта” ви можете тут .</p>
                <p>Після відправлення замовлення, ви отримаєте номер ТТН (товарно-транспортної накладної), по якій ви зможете відстежити посилку.</p>
            </div>
            <div className={styles.row}>***З моменту передачі товару для перевізника право власності на товар та ризик його випадкового знищення або пошкодження переходить до замовника.</div>
        </>
    )
}

export default DeliveryAndPayment