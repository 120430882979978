import React from 'react'
import styles from '../Info.module.css'

function ReturnAndChange() {
    return (
        <>
            <div className={styles.row}>
                <p>При <span className={styles.mark}>Індивідуальному Пошитті Товару</span>, товар підлягає обміну або поверненню ЛИШЕ в таких випадках:</p>
                <p>*  Під час виготовлення товару продавець не дотримався індивідуальних параметрів, наданих замовником.</p>
                <p>** Продавець виготовив товар з наявністю дефекту/браку.</p>
                <p>** Продавець припустився помилки у кольорі/ моделі/ матеріалі (якщо така інформація була надана).</p>
                <p> Збережено товарний вид виробу, цілісність упаковки, наявність бірок та чеку, який підтверджує покупку.</p>
            </div>
            <div className={styles.row}>
                <p>Процес обміну або повернення відбувається в такому порядку:</p>
                <p>* У день отримання Товару повідомити менеджера в Інстаграмі про наявність підстав для обміну або повернення.</p>
                <p>* Отримати підтвердження від менеджера про можливість обміну або повернення Товару.</p>
                <p>* Протягом 2-х днів після дня отримання товару передати його перевізнику для доставки за адресою, що вказана внизу сторінки.</p>
            </div>
            <div className={styles.br}></div>
            <div className={styles.row}>
                <p>Виправлення браку/дефекту, пошиття нового товару відбувається за скороченими термінами.</p>
                <p>Усі витрати, пов’язані з поверненням/обміном товару, сплачує продавець.</p>
            </div>
            <div className={styles.br}></div>
            <div className={styles.row}>
                <p>У випадку придбання товару <span className={styles.mark}>з наявності</span> ви можете повернути/обміняти товар протягом 14 календарних днів з моменту отримання </p>
                <p>замовлення, за умови збереження товарного виду виробу, цілісності упаковки, наявності бірок та чеку, який підтверджує покупку.</p>
            </div>
            <div className={styles.row}>
                <p>** Повернення/обмін товару здійснюється службою доставки «Нова Пошта». Усі витрати, пов’язані з поверненням/обміном товару належної якості, несе покупець.</p>
                <p>** Вживаний товар обміну та поверненню не підлягає.</p>
            </div>
            <div className={styles.row}>
                <p>Процес обміну/повернення відбувається в такому порядку:</p>
                <p>** Напишіть нашому менеджеру в Інстаграм.</p>
                <p>** Заповніть форму для повернення/ уточніть наявність бажаної речі для обміну товару.</p>
                <p>** Після підтвердження всіх деталей надішліть річ, що вам не підійшла за адресою, що вказана внизу сторінки.</p>
            </div>
            <div className={styles.br}></div>
            <div className={styles.br}></div>
            <div className={styles.row}>У випадку обміну, доставка нового товару здійснюється після отримання попереднього товару назад. Кошти за товар повертаються протягом 5 днів після його фактичного отримання.</div>
            <div className={styles.row}>Ми маємо право відмовити в обміні/поверненні товару, якщо не дотримані правила й умови, описані в цьому розділі.</div>
            <div className={styles.br}></div>
            <div className={styles.br}></div>
            <div className={styles.row}><span className={styles.mark}>АДРЕСА ДЛЯ ВІДПРАВКИ ТОВАРУ:</span></div>
            <div className={styles.row}>
                <p>м. Запоріжжя </p>
                <p>+380509838053</p>
                <p>Відділення Нової Пошти #4</p>
                <p>ФОП Денисова Валентина Олександрівна </p>
            </div>

        </>
    )
}

export default ReturnAndChange