import React from 'react'
import { FRONT_URL } from '../const'
import styles from '../styles/About.module.css'


function About() {
    return (
        <>
            <div className={styles.image}>
                <img src={`${FRONT_URL}/images/about-bg.png`} alt="bg" />
            </div>
            <div className={styles.info}>
                <div className={styles.row1}>
                    <span className={styles.label}>CAVISTUDIO</span> - це про новий погляд на звичні речі, про поєднання, здавалось б, непоєднуваного, 
                    про експерименти та підкреслення індивідуальності, про виклик, про трансляцію внутрішнього 
                    та прийняття себе. Про дозвіл відрізнятись.
                </div>
                <div className={styles.row2}>
                    Бренд створений двома сестрами, основна мета якого зробити простір, в якому кожен зможе 
                    знайти щось своє, щось унікальне та водночас просте, щось зрозуміле, але не для кожного, 
                    створити такі речі, які можливо було б носити скрізь.
                </div>
                <div className={styles.row3}>
                    <p>
                        Для нас важливо дати змогу кожному клієнту відчути себе в процесі створення виробу, 
                        дати можливість самостійно втілити бачення свого ідеального костюма, футболки, лонгу, 
                        не обмежувати у виборі кольорового поєднання та розміру.
                    </p>
                    <p>
                        Саме тому кожен виріб виготовляється з урахуванням індивідуальних параметрів клієнта, щоб 
                        кожна людина, незалежно від зросту / фігури / її особливостей, відчувала себе комфортно.
                    </p>
                </div>
                <div className={styles.row4}>
                    <p>І, звичайно, в розділі про нас, ми не можемо забути найголовніше - Ви.</p>
                    <p>Усе, чого ми досягли, стало можливим завдяки всім вам.</p>
                </div>
                <div>З величезною любовʼю, сестри та команда!</div>
            </div>
        </>
    )
}

export default About